import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import PhotoAlbum, { RenderPhoto } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useUI } from "../../context/ui.context";

const data = {
  gkey: process.env.REACT_APP_GKEY,
  dirId: process.env.REACT_APP_DIR_ID,
  // jogo1: "1YmNoZsYjQWxGSVig4m6ytlMbaP1Ut1QF",
};

const GOOGLE_API_KEY = data.gkey;
const GOOGLE_DRIVE_URL_START =
  "https://www.googleapis.com/drive/v2/files?q=%27";
const GOOGLE_DRIVE_URL_END = "%27+in+parents&maxResults=1000&key=";
const GOOGLE_DRIVE_IMG_URL = "http://drive.google.com/uc?export=view&id=";
const GOOGLE_DRIVE_IMG_THUMBS_URL =
  "https://drive.google.com/thumbnail?authuser=0&sz=w320&id=";

const HomePage = () => {
  const { currentFolder, setCurrentFolder } = useUI();

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [images, setImages] = useState([]);
  const [slides, setSlides] = useState([]);
  const [folders, setfolders] = useState([]);
  // const queryParams = new URLSearchParams(window.location.search);
  // const params = queryParams.get("f");
  const { folderId } = useParams();

  useEffect(() => {
    loadData(folderId);
  }, [folderId]);

  async function loadData(folderId: any) {
    setIsLoading(true);
    await fetch(
      GOOGLE_DRIVE_URL_START +
        (folderId ?? data.dirId) +
        GOOGLE_DRIVE_URL_END +
        GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        console.log(jsonResp);
        populateImages(jsonResp.items);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function checkFormat(url: string) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  function populateImages(items: any) {
    const folders: any = [];
    const images: any = [];
    const slides: any = [];
    items.map((item: any, i: number) => {
      if (checkFormat(item.title)) {
        images.push({
          // src: GOOGLE_DRIVE_IMG_URL + item.id,
          src: GOOGLE_DRIVE_IMG_THUMBS_URL + item.id,
          width: item.imageMediaMetadata.width * 0.3,
          height: item.imageMediaMetadata.height * 0.3,
        });
        slides.push({
          src: GOOGLE_DRIVE_IMG_URL + item.id,
          width: item.imageMediaMetadata.width,
          height: item.imageMediaMetadata.height,
        });
      } else {
        folders.push({
          id: item.id,
          title: item.title,
          src: "folder.png",
          width: "100",
          height: "100",
        });
      }
    });
    setImages(images);
    setSlides(slides);
    setfolders(folders);
    folders.sort((a: any, b: any) => a.title.localeCompare(b.title));
  }

  if (isLoading) {
    return (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <>
      {folders.length > 0 ? (
        <div className="main-containerx">
          {/* <div className="top-banner">
            <a href="https://west.com.br" target="_blank" rel="noreferrer">
              <img src={"/banner1.jpg"} alt={"west"} />
            </a>
          </div> */}
          <div className="areas">
            <div className="folders-container">
              {folders.map((item: any, idx: number) => (
                <div key={idx} className="image-container">
                  {/* <Link to={`/?f=${item.id}`}> */}
                  <Link
                    to={`/${item.id}`}
                    onClick={() => {
                      setCurrentFolder(item);
                    }}
                  >
                    {/* <a href={`/?f=${item.id}`}> */}
                    <img
                      src={item.src}
                      width={item.width}
                      height={item.height}
                      alt={item.title}
                    />
                    {/* </a> */}
                  </Link>
                  <h2>{item.title}</h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          {currentFolder && (
            <div className="title-area">
              <h2>{currentFolder.title}</h2>
            </div>
          )}

          {images.length > 0 && (
            <PhotoAlbum
              photos={images}
              layout="masonry"
              // layout="columns"
              // layout="rows"
              // targetRowHeight={150}
              onClick={(event, photo, index) => setIndex(index)}
            />
          )}

          {slides.length > 0 && (
            <Lightbox
              slides={slides}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
          )}
        </>
      )}
    </>
  );
};

export default HomePage;
