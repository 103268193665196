import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import { ManagedUIContext } from "./context/ui.context";
import "./index.css";

const container = document.getElementById("root");
if (!container) throw new Error("root container not found");

// if (process.env.NODE_ENV !== "development") console.log = () => {};

createRoot(container).render(
  <ManagedUIContext>
    <StrictMode>
      <header>
        <h1>
          <a
            href="https://west.com.br"
            target="_blank"
            rel="noreferrer noopener"
          >
            WEST FIBRA | EVENTOS
          </a>
        </h1>
        <div className="">
          {/* <a href="https://west.com.br" target="_blank" rel="noreferrer noopener">
          West Fibra
        </a> */}
          <a href="/" rel="noreferrer noopener">
            Listar Eventos
          </a>
        </div>
      </header>
      <main>
        <App />
      </main>
    </StrictMode>
  </ManagedUIContext>
);
