import React from "react";

export interface State {
  currentFolder?: any;
}

const initialState = {
  currentFolder: null,
};

type Action =
  | {
      type: "SET_CURRENT_FOLDER";
      folder: any;
    }
  | {
      type: "UNSET_CURRENT_FOLDER";
    };

export const UIContext = React.createContext<State | any>(initialState);

UIContext.displayName = "UIContext";

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_CURRENT_FOLDER": {
      return {
        ...state,
        currentFolder: action.folder,
      };
    }
    case "UNSET_CURRENT_FOLDER": {
      return {
        ...state,
        currentFolder: null,
      };
    }
  }
}

export const UIProvider: React.FC<any> = (props) => {
  const [state, dispatch] = React.useReducer(uiReducer, initialState);

  const setCurrentFolder = (folder: any) => {
    console.log("xxxx");
    dispatch({ type: "SET_CURRENT_FOLDER", folder: folder });
  };
  const unsetCurrentFolder = () => dispatch({ type: "UNSET_CURRENT_FOLDER" });

  const value = React.useMemo(
    () => ({
      ...state,
      setCurrentFolder,
      // unsetCurrentFolder,
    }),
    [state]
  );
  return <UIContext.Provider value={value} {...props} />;
};

export const useUI = () => {
  const context = React.useContext(UIContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context;
};

export const ManagedUIContext: React.FC<any> = ({ children }) => (
  <UIProvider>{children}</UIProvider>
);
